import $ from "jquery";
import "jquery-validation";

$(document).ready(function () {
  $(".services_tabs_check").click(function () {
    $(".header_services_info_tab").hide();
    $("#content-" + $(this).attr("id")).show();
  });
  $(function () {
    $(".show_more").click(function () {
      $(this).closest(".urist").find("li:nth-child(n+4)").slideToggle("");

      if ($(this).hasClass("rotate") == false) {
        $(this).addClass("rotate");
        $(this).html("свернуть");
      } else {
        $(this).removeClass("rotate");
        let a = $(this).prev("ul").children("li").length - 3;
        $(this).html("и ещё " + a + " специализаций");
      }
    });
  });
  $(".request_form_show").click(function () {
    $("#request_form").show();
    return false;
  });
  $(".call_btn").click(function () {
    $("#callback").show();
    return false;
  });
  $(".modal_close_icon").click(function () {
    $(this).closest(".dm-overlay").hide();
    $("html, body").css("overflow", "");
    return false;
  });

  $(function () {
    var $menu_popup = $(".menu-popup");

    $(".menu-triger, .menu-close").click(function () {
      $menu_popup.slideToggle(300, function () {
        if ($menu_popup.is(":hidden")) {
          $("body").removeClass("body_pointer");
          $("html, body").css("overflow", "");
        } else {
          $("body").addClass("body_pointer");
          $("html, body").css("overflow", "hidden");
        }
      });
      return false;
    });

    $(document).on("click", function (e) {
      if (!$(e.target).closest(".menu").length) {
        $("body").removeClass("body_pointer");
        $menu_popup.slideUp(300);
      }
    });
  });
  $("a.scrollto").click(function () {
    $("html, body").animate(
      {
        scrollTop: $($(this).attr("href")).offset().top + "20px",
      },
      1000
    );
  });

  ymaps.ready(function () {
    var myMap = new ymaps.Map(
        "map",
        {
          center: [55.808776, 37.613054],
          zoom: 15,
        },
        {
          searchControlProvider: "yandex#search",
        }
      ),
      MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
        '<div  style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      ),
      myPlacemark = new ymaps.Placemark(
        myMap.getCenter(),
        {},
        {
          iconLayout: "default#image",
          iconImageHref: "static/img/Pin.svg",
          iconImageSize: [120, 120],
          iconImageOffset: [-60, -120],
        }
      );

    myMap.geoObjects.add(myPlacemark);
  });
  $(".js-submit").click(function () {
    const $form = $(this).closest(".js-credit-form");
    if (!$form) {
      return;
    }
    const $submitBtn = $(this);
    let ajaxIsRunning = false;
    $form.validate({
      rules: {
        fio: { required: true },
        phone: { required: true },
      },
      messages: {
        fio: "Заполните обязательное поле",
        phone: "Заполните обязательное поле",
      },
      errorPlacement: function ($error, $element) {
        $element.addClass("error");
      },
      unhighlight: function (element) {
        $(element).removeClass("error");
      },
      highlight: function (element) {},
      success: function (element) {
        $(element).removeClass("error");
      },
      submitHandler: function (form) {
        if (!ajaxIsRunning) {
          ajaxIsRunning = true;
          const $form = $(form);
          const action = $form.attr("action");
          const method = $form.attr("method");
          $submitBtn.prop("disabled", true);

          $.ajax({
            url: action,
            type: method,
            data: $form.serialize(),
            async: true,
            success: function (data) {
              if (data.success) {
                $(".dm-overlay").hide();
                setTimeout(function () {
                  $("#thanks").show();
                  $("html, body").css("overflow", "hidden");
                }, 1000);
              }
              ajaxIsRunning = false;
              $submitBtn.prop("disabled", false);
            },
            error: function () {},
          });
        }
        return false;
      },
    });
  });
});
